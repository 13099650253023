body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: none;
  touch-action: manipulation;
}

body{
  top:0 !important
}

.goog-te-combo{
  width: 150px !important;
}

.error{
  display: inline-block !important;
}

#goog-gt-original-text{
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none !important;
}

.VIpgJd-suEOdc{
  display: none !important;
}