.driver_icon_main {
    display: flex;
    gap: 5rem;
    justify-content: space-between;
    align-items: center;
}

.driver_icon_main_image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
}

.driver_icon_main_detail {
    display: flex;
    flex-direction: column !important;
    gap: 0.5rem !important;
    justify-content: start !important;
}

.driver_icon_main_detail_first {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: -5px !important;
}

.driver_icon_main_image img {
    margin-top: 10px !important;
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
}



.driver_icon_main div {
    font-size: 16px;
    /* display: inline-block; */
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
    color: #444444;
}

/* .driver_icon_main_detail_first_left {
    display: flex;
    align-items: center;
} */


.driver_icon_main_detail_first_left div {
    margin-top: 10px !important;
    /* padding-left: 5px !important; */
    display: inline-flex !important;
    padding-right: 5px !important;

}

.driver_icon_main_detail_third, .driver_icon_main_detail_second {
    display: flex !important;
    justify-content: start !important;
}

.driver_icon_main_detail_first_left {
    display: grid !important;
    justify-content: flex-start !important;
    text-align: start !important;
}

.driver_icon_main_detail_first_right{
    font-weight: 700
}