
#payment-form {
  border: #F6F9FC solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size:0.7em;
} 


/* Wrapper Styles */
.wrapper {
  /* background: red; */
  height: 116px;
  width: 400px;
  perspective: 300px;
}

/* Card Styles */
.card {
  background: linear-gradient(#3498db, #2980b9); /* Assuming $card-color = #3498db */
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 115px; /* Assuming $card-height = 250px */
  width: 300px; /* Assuming $card-width = 400px */
}

/* Cards Container Styles */
.cards {
  /* Face Card */

color: white;

  .face {
      /* Extending .card styles */
      background: #4f494975;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
      border-radius: 1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 108px;
      width: 380px;

      /* Head Section */
      .head {
          display: flex;
          justify-content: space-between;
          margin: 16px 16px 0 16px;
          .title {
              font-weight: 600;
              font-size: 20px;
          }
      }

      /* Main Section */
      .main {
          margin: 16px 16px 0 16px;
          .number-title {
              color: white; /* Assuming darken(#fff, 20%) is #cccccc */
          }
          .number {
              font-size: 18px;
          }
      }

      /* Dates Section */
      .dates {
          display: flex;
          max-width: 100%;
          justify-content: space-between;
          margin: 16px 16px 16px 16px;
          .start {}
          .end {}
      }
  }

  /* Back Card */
  .back {
      animation: demo 2.5s ease-in-out; /* Assuming $animation-speed = 0.5s */
      transform: translateX(0) translateY(0);
      transition: transform 0.5s; /* $animation-speed = 0.5s */
      background: linear-gradient(#2980b9, #1a5987); /* darken(#3498db, 4) to darken(#3498db, 14) */
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      /* Magnetic Line */
      .magnetic-line {
          background: #000;
          margin-top: 16px;
          height: 32px;
          width: 100%;
      }

      /* CVV Section */
      .cvv {
          display: flex;
          justify-content: center;
          flex-direction: column;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          margin: 0 16px 83px 0; /* $card-height / 3 assuming 250px / 3 = 83px */
          text-align: end;
          padding-right: 8px;
          width: 275px; /* $card-width / 1.5 + 9 assuming 400 / 1.5 + 9 */
          height: 24px;
      }
  }
}

/* Hover Effect */
.cards:hover .back {
  transform: translateX(200px) translateY(-50px); /* $card-width / 2 */
  transition: transform 0.5s; /* Assuming $animation-speed = 0.5s */
}

/* Animation Keyframes */
@keyframes demo {
  0% {
      transform: translateX(0) translateY(0);
  }
  50% {
      transform: translateX(200px) translateY(-50px); /* $card-width / 2 */
  }
  75% {
      transform: translateX(200px) translateY(-50px); /* $card-width / 2 */
  }
  100% {
      transform: translateX(0) translateY(0);
  }
}

/* Twitter Follow Button */
.twitter-follow-button {
  position: absolute !important;
  top: 8px;
  left: 8px;
}


.OffsetContainer {
  display: none !important;
}

.Button--pay {
  display: none !important;
}